<template>
  <v-app>
    <v-main>
      <v-container
        class="admin"
      >
        <v-row>
          <v-col cols="12">
            <h3 class="text-h3 mb-12">KYBURZ Showroom Display Slideshow</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <h5 class="text-h5 mb-6">Sorter</h5>
            <List
              :files="files"
              @deleteimage="deleteImage"
              @update="initData"
            />
          </v-col>
          <v-col cols="6">
            <h5 class="text-h5 mb-6">File-Uploader</h5>
            <FileUploader
              @update="initData"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import List from '@/components/List.vue';
import FileUploader from '@/components/FileUploader.vue';

export default {
  name: 'Admin',
  components: {
    List,
    FileUploader,
  },
  data: () => ({
    files: [],
  }),
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.$apiGetImages()
        .then((response) => {
          this.files = response.data;
        })
        .catch((error) => {
          /* eslint-disable */
          console.log('error', error);
          /* eslint-enable */
        });
    },
    deleteImage(id) {
      this.$apiDeleteImage(id)
        .then((response) => {
          if (response.data) {
            this.initData();
          } else {
            /* eslint-disable */
            console.log('%c Foto konnte auf dem Server nicht gelöscht werden ', 'background-color: red; color: white;');
            /* eslint-enable */
          }
        })
        .catch((error) => {
          /* eslint-disable */
          console.log('error', error);
          /* eslint-enable */
        });
    },
  },
};
</script>

<style lang="scss">
  .admin {
    margin-top: 5rem;
  }
</style>
