<template>
  <div class="file-uploader">
    <v-sheet
      class="file-uploader__sheet d-flex flex-column justify-center align-center"
      :class="{ 'file-uploader__sheet--dragging': dragging }"
      elevation="7"
      height="100%"
      width="100%"
      @drop.prevent="addDropFiles"
      @dragover.prevent="dragging = true"
      @dragleave.prevent="dragging = false"
    >
      <v-file-input
        class="flex-grow-0 file-uploader__input"
        chips
        counter
        label="Images"
        hint="jpg"
        persistent-hint
        multiple
        show-size
        v-model="files"
        truncate-length="50"
        prepend-icon="mdi-camera"
      ></v-file-input>
      <v-btn
        v-if="files.length > 0"
        class="mt-4"
        elevation="3"
        large
        @click="startUpload"
      >
        <v-icon
          dark
          left
        >
          mdi-upload
        </v-icon>
        upload
      </v-btn>
      <v-progress-circular
        class="mt-4"
        v-if="uploading"
        indeterminate
      ></v-progress-circular>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: 'FileUploader',
  data: () => ({
    files: [],
    uploadFiles: [],
    dragging: false,
    uploading: false,
  }),
  methods: {
    startUpload() {
      this.uploading = true;
      this.prepareUpload().then(() => {
        this.upload()
          .then(() => {
            this.files = [];
            this.uploadFiles = [];
            this.uploading = false;
            this.$emit('update');
          }).catch((error) => {
            /* eslint-disable */
            console.log('error', error);
            /* eslint-enable */
          });
      });
    },
    prepareUpload() {
      return new Promise((resolve) => {
        const promises = [];
        this.files.forEach((file) => {
          promises.push(new Promise((resolve2) => {
            const fr = new FileReader();
            fr.readAsDataURL(file);
            fr.addEventListener('load', () => {
              const data = {
                filename: file.name,
                dataUrl: fr.result,
              };
              this.uploadFiles.push(data);
              resolve2();
            });
          }));
        });

        Promise.all(promises).then(() => {
          resolve();
        });
      });
    },
    upload() {
      return this.$apiUploadImages(this.uploadFiles);
    },
    addDropFiles(e) {
      this.files.push(...Array.from(e.dataTransfer.files));
      this.dragging = false;
    },
  },
};
</script>

<style lang="scss">
.file-uploader {
  width: 100%;

  &__input {
    width: 100%;
  }

  &__sheet {
    min-height: 500px;
    padding: 3rem;
    transition: background .3s ease-in;

    &--dragging {
      transition: background .3s ease-in;
      background-color: #333333 !important;
    }
  }
}
</style>
