<template>
  <div
    v-if="files.length > 0"
    class="list d-flex flex-column justify-center"
  >
    <draggable
      v-model="localFiles"
      group="files"
      @start="dragStart"
      @end="dragEnd"
      handle=".button--move"
    >
      <transition-group type="transition" name="flip-list">
        <v-card
          v-for="file in localFiles"
          :key="file.id"
          elevation="7"
          class="list__element mb-2"
        >
          <v-card-title>
            <v-img
              height="90"
              width="160"
              :src="file.dataUrl"
            />
          </v-card-title>
          <v-card-text
            class="pb-0"
          >
            {{file.filename}}
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="$emit('deleteimage', file.id)"
              icon
              class="button button--delete"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              icon
              class="button button--move"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </transition-group>
    </draggable>
    <transition name="fade">
      <v-btn
        v-if="sortChanged"
        @click="saveSort"
        class="flex-grow-0 mt-3"
      >
        <v-icon left>
          mdi-content-save
        </v-icon>
        Sortierung speichern
      </v-btn>
    </transition>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'List',
  components: {
    draggable,
  },
  props: {
    files: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    localFiles: [],
    drag: false,
    sortChanged: false,
  }),
  watch: {
    files(newFiles) {
      this.localFiles = newFiles;
    },
  },
  methods: {
    dragStart() {
      this.drag = true;
    },
    dragEnd() {
      this.drag = false;
      this.sortChanged = true;
    },
    saveSort() {
      const sortArray = this.createSortArray();
      this.$apiSortImages(sortArray)
        .then(() => {
          this.$emit('update');
          this.sortChanged = false;
        });
    },
    createSortArray() {
      const result = [];
      this.localFiles.forEach((file, index) => {
        result.push({
          id: file.id,
          sort: index,
        });
      });
      return result;
    },
  },
};
</script>

<style lang="scss">
.list {

  &__element {
    display: flex !important;
    flex-direction: row;
    align-items: center;
  }
}

.button {

  &--move {
    cursor: grab;
  }
}

.flip-list-move {
  transition: transform 1s;
}
</style>
